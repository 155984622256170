<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    :visible="isHandlerSidebarActive"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-handler-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Enviar email
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Body -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- From -->
          <!--        <b-form-group-->
          <!--          label="From"-->
          <!--          label-for="from"-->
          <!--        >-->
          <!--          <b-form-input-->
          <!--            id="from"-->
          <!--            v-model="sendInvoiceData.from"-->
          <!--            trim-->
          <!--            type="email"-->
          <!--          />-->
          <!--        </b-form-group>-->
          <!-- To -->
          <b-form-group
            label="Para:"
            label-for="to"
          >
            <b-form-input
              id="to"
              :value="quotation.contact !== undefined ? quotation.contact.email : quotation.email"
              trim
              plaintext
            />
          </b-form-group>
          <!-- To -->
          <b-form-group
            :state="state"
            label="Enviar a correo adicionals"
            label-for="tags-validation"
          >
            <b-form-tags
              v-model="tags"
              input-id="tags-validation"
              :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
              :state="state"
              :tag-validator="tagValidator"
              remove-on-delete
              placeholder=""
              separator=" "
            />
            <!-- The following slots are for b-form-group -->
            <template #invalid-feedback>
              Email inválido
            </template>

          </b-form-group>

          <!-- Subject -->
          <!--          <b-form-group-->
          <!--            label="Asunto"-->
          <!--            label-for="subject"-->
          <!--          >-->
          <!--            <b-form-input-->
          <!--              id="subject"-->
          <!--              v-model="emailObject.subject"-->
          <!--              trim-->
          <!--            />-->
          <!--          </b-form-group>-->

          <!-- Message -->
          <b-form-group
            label="Mensaje adicional"
            label-for="message"
          >
            <b-form-textarea
              id="message"
              v-model="emailObject.message"
              rows="4"
              trim
            />
          </b-form-group>

          <!-- File -->
          <validation-provider
            #default="validationContext"
            name="Adjuntar"
          >
            <b-form-group
              label="Adjuntar"
              label-for="file-email"
            >
              <b-form-file
                id="file-email"
                ref="refInputElDesktop"
                v-model="emailObject.file"
                :state="getValidationState(validationContext)"
                placeholder="Archivo adicional"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ? File/Attachement -->
          <!--          <b-badge variant="light-primary">-->
          <!--            <feather-icon icon="LinkIcon" />-->
          <!--            <span class="ml-50">Cotización Attached</span>-->
          <!--          </b-badge>-->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Enviar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, BFormInvalidFeedback, BFormFile, BFormTags,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, email, numeric, digits,
} from '@validations'
import store from '@/store'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BBadge,
    BFormInvalidFeedback,
    BFormFile,
    BFormTags,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isHandlerSidebarActive',
    event: 'update:is-handler-sidebar-active',
  },
  props: {
    quotation: {
      type: Object,
      required: true,
    },
    isHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      numeric,
      digits,
    }
  },
  setup(props, { emit }) {
    const blankProductData = {
      from: '',
      to: '',
      subject: '',
      message: '',
      file: null,
    }
    const tags = ref([])
    const state = ref(null)

    const tagValidator = tag => {
      const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      state.value = emailRegex.test(tag)
      return emailRegex.test(tag)
    }

    watch(tags, val => {
      if (val.length === 0) state.value = null
    })

    const emailObject = ref(JSON.parse(JSON.stringify(blankProductData)))

    const resetEmailData = () => {
      emailObject.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEmailData)

    const onSubmit = () => {
      const formData = new FormData()
      formData.append('contactId', props.quotation.contacto_id)
      formData.append('quotationId', props.quotation.id)
      formData.append('message', emailObject.value.message)
      // formData.append('subject', emailObject.value.subject)
      if (emailObject.value.file) {
        formData.append('file', emailObject.value.file)
      }
      tags.value.forEach((item, index) => {
        formData.append(`emails[${index}]`, item)
      })

      store.dispatch('app-quotations/sendEmail', { body: formData })
        .then(() => {
          showNotification({ title: 'Correo enviado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          emit('update:is-handler-sidebar-active', false)
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      emailObject,

      onSubmit,

      refFormObserver,
      clearForm,
      resetForm,
      getValidationState,

      tagValidator,
      tags,
      state,

    }
  },
}
</script>

<style>

</style>
